//  @flow
import * as firebase from 'firebase/app';
import 'firebase/database';

export const push = (path: string, object: Object): any => {
  return firebase
    .database()
    .ref(path)
    .push(object);
};

export const pushList = (path: string, list: Array<Object>): void => {
  const ref = firebase.database().ref(path);
  list.forEach((obj: Object) => ref.push(obj));
};

export const get = (path: string): Promise<any> => {
  console.log('o get do firebase', path)
  return firebase
    .database()
    .ref(path)
    .once('value')
    .then(snap => snap.val());
};

export const getLast = (path: string, limit: number): Promise<any> => {
  return firebase
    .database()
    .ref(path)
    .limitToLast(limit)
    .once('value')
    .then(snap => snap.val() || {});
};

export const set = (path: string, value: string | number | boolean | Object): Promise<any> => {
  return firebase
    .database()
    .ref(path)
    .set(value);
};

export const turnOffListener = (path: string): void => {
  firebase
    .database()
    .ref(path)
    .off();
};

export const listenOnLastAdd = (path: string, callback: Function): void => {
  firebase
    .database()
    .ref(path)
    .limitToLast(1)
    .on('child_added', snap => callback(snap.val()));
};

export const listenOnValue = (path: string, callback: Function): void => {
  firebase
    .database()
    .ref(path)
    .on('value', snap => callback(snap.val()));
};
