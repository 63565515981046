// @flow
import { withScope, captureException, configureScope } from '@sentry/browser';
import * as FirebaseAuth from '../../firebase/FirebaseAuth';
import * as AuthServer from '../../server/AuthServer';
import { AuthActions, UserActions } from '../../redux/actions';
import VideoController from '../video/controller';
import Cookies from 'js-cookie';
// import { setUpSecurity } from './SecurityController';

const getParsedProfile = (userProfile: Object): UserProfile => {
  const parsedProfile = {
    ...userProfile,
    hasCalendarAssociated: userProfile.has_calendar_associated
  };
  const { has_calendar_associated, ...finalProfile } = parsedProfile;
  return finalProfile;
};

export const logIn = (credentials) => (dispatch) => {
  // console.log('credentials do logIn: ', credentials)
  return AuthServer.signInParticipant(credentials)
    .then(userProfile => {
      // console.log('userProfile returned: ', userProfile)
      dispatch(AuthActions.setLoginServerSuccess());
      return FirebaseAuth.logIn(credentials)
        .then((firebaseAuth: Object) => {
          // console.log('firebaseAuth returned: ', firebaseAuth)
          const { emailVerified, uid } = firebaseAuth.user;
          // console.log('saveFirebaseAuthData: ')
          dispatch(AuthActions.saveFirebaseAuthData({ emailVerified, uid }));
          // console.log('setLoginFirebaseSuccess ')
          dispatch(AuthActions.setLoginFirebaseSuccess());
          
          VideoController.setVideoCallListener(dispatch, userProfile?.chat_id)

          dispatch(UserActions.saveUserProfile(userProfile));

          localStorage.setItem('name', JSON.stringify(userProfile?.user?.name));

          // setting cookie variables
          Cookies.set('uid', JSON.stringify(userProfile?.user?.id))
          Cookies.set('cid', JSON.stringify(userProfile?.chat_id))
          Cookies.set('token', JSON.stringify(userProfile?.auth_token))
          Cookies.set('video', JSON.stringify(null))
          // Retorno o profile que vai botar no redux para pegar
          // o uid do terapeuta e configurar o Virgil SEcurity
          return userProfile;
        })
        .catch(err => {
          dispatch(AuthActions.setLoginFirebaseFailed());
          throw err;
        });
    })
    .catch(err => {
      dispatch(AuthActions.setLoginServerFailed());
      throw err;
    });
}

// export const logOut = () => (dispatch: Dispatch) =>
//   AuthServer.logOut().finally(() => dispatch(AuthActions.setLoginServerFailed()));

export const logOut = () => (dispatch: Dispatch) =>
  AuthServer.logOut().then(() => {
    // // console.log('FEZ O LOGOUT E VAI LIMPAR')
    dispatch(AuthActions.setLogoutServerSuccess());
    dispatch(AuthActions.setLogoutFirebaseSuccess());

    // Limpando as outras partes do redux
    // dispatch(ChatActions.clearChatsInfo());
    dispatch(UserActions.clearUserProfile());
    // dispatch(AppointmentsActions.clearUpcomingAppointments());
    // Limpando as infos do chat atual
    // dispatch(ChatActions.setCurrentChat(undefined));
    // dispatch(ChatActions.setMessages({}));
  });

const reportError = error => {
    withScope((scope) => {
        scope.setExtras(error);
        const eventId = captureException(error);
    });
  }

// export const refreshAuthenticatedFlag = () => (dispatch: Dispatch) =>
//   Promise.all([
//     AuthServer.isAuthenticated()
//       .then((profile: Object) => {
//         const parsedProfile: UserProfile = getParsedProfile(profile);
//         // // console.log('PROFILE PARSED: ', parsedProfile)
//         // ASSIM QUE LOGA VAMOS SETAR O CONTEXTO DO SENTRY PARA TER INFOS DE ERRO
//         configureScope(scope => {
//           scope.setUser({'name': parsedProfile.name});
//           scope.setExtra({'therapistUid': parsedProfile.uid});
//         });
//         dispatch(UserActions.saveUserProfile(parsedProfile));
//       })
//       .then(() => dispatch(AuthActions.setLoginServerSuccess()))
//       .catch(error => {
//         reportError(error);
//         dispatch(AuthActions.setLoginServerFailed());
//       }),
//     FirebaseAuth.isAuthenticated()
//       .then((uid: string) => {
//         dispatch(AuthActions.setLoginFirebaseSuccess(uid));
//         return uid;
//       })
//       .catch(error => {
//         // // console.log('MERDA NO FIREBASE: ', error);
//         reportError(new Error('firebase.auth().onAuthStateChanged não retornou usuário logado'));
//         dispatch(AuthActions.setLoginFirebaseFailed());
//       })
//   ]).then(async resp => {
//       const therapistUid = resp[1];
//       // // console.log('DEU AUTENTIC: ', resp);
//       if (therapistUid) {
//         await setUpSecurity(therapistUid);
//         dispatch(AuthActions.setUpSecuritySuccess());
//       } else {
//         // // console.log('NAO TA LOGADO NO FIREBASE')
//         dispatch(AuthActions.setUpSecurityFailed());
//       }
//     }
//   );
