import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import AuthReducer from './auth';
import HeaderReducer from './HeaderReducer';

export default combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  headerButton: HeaderReducer
});
