// @flow
import * as UserTypes from '../types/UserTypes';

export const saveUserProfile = (profile: UserProfile): Action => ({
  type: UserTypes.SAVE_USER_PROFILE,
  payload: profile
});

export const clearUserProfile = (): Action => ({
  type: UserTypes.CLEAR_USER_PROFILE,
  payload: {}
});

export const saveVideoToken = (accessToken: string): Action => ({
  type: UserTypes.SAVE_VIDEO_ACCESS_TOKEN,
  payload: { videoAccessToken: accessToken }
});

export const deleteVideoToken = (): Action => ({
  type: UserTypes.DELETE_VIDEO_ACCESS_TOKEN,
  payload: {}
});
