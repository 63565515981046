// @flow
import Video from 'twilio-video';
import * as Sentry from '@sentry/browser';
import { UserActions } from '../../redux/actions';
import { FirebaseDatabase } from '../../firebase';
import styles from './Video.module.css';
import Cookies from 'js-cookie'

class VideoController {

  constructor() {
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    this.remoteMedia = null;
    this.localMedia = null;
  }

  setLocalTrack = (track: Object, type: string) => {
    if (type === 'audio') {
      this.localAudioTrack = track;
    } else if (type === 'video') {
      this.localVideoTrack = track;
    }
  }

  setMedia = (media: Object, type: string) => {
    if (type === 'local') {
      this.localMedia = media;
    } else if (type === 'remote') {
      this.remoteMedia = media;
    }
  }

  getAccessToken = (chatId: string) => (dispatch: Dispatch) => {
    return FirebaseDatabase.get(`chats/${chatId}/patientVideoToken`).then(val => {
      // dispatch(UserActions.saveVideoToken(val))
      return val;
      }).catch(error => console.log('error no getAccessToken: ', error));
  }

  listenToVideoTokenActivation = (chatId: string, callback: Function) => {
    FirebaseDatabase.listenOnValue(`chats/${chatId}/patientVideoToken`, callback);
  }

  // const clearVideo = (container: Object) => {
  //   const video = container.querySelector('video');
  //   if (video) {
  //     video.remove();
  //   }
  // };

  sendErrorToSentry = (error, patient, therapist) => {
    Sentry.withScope(scope => {
      scope.setExtra("patient", patient)
      scope.setExtra("therapist", therapist)
      Sentry.captureException(error)
    });
  }

  addTrack = (track: Object, container: Object) => {
    // clearVideo(container);
    // console.log('no add')
    try {
      const attached = track.attach();
      // console.log('VAI ADD TRACK: ', track);
      // console.log('ATTACH; ', attached);
      container.appendChild(track.attach());
      // // console.log(container);
      const video = container.querySelector('video');
      // // console.log(video);

      if (video) {
        // console.log('IDENTIFICOU VIDEO E VAI MUDAR STYLE');
        video.setAttribute('class', styles.video);
      } else {
        Sentry.withScope(scope => {
          scope.setExtra("track", track)
          Sentry.captureException(new Error('Sem vídeo associado. Container null!'))
        });
      }
    } catch (error) {
      // console.log('error no addTrack', error)
      // Sentry.captureException(error);
    }
  };

  removeTrack = (track: Object) => {
    try {
      // // console.log('VAI REMOVER TRACK: ', track);
      track.detach().forEach(element => element.remove());
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  addParticipantMedia = (participant: Object, container: Object) => {
    try {
      const tracks = [...participant.tracks.values()];
      // console.log('VAI ADICIONAR PARTICIPANTE NA CHAMADA: ', participant);
      // console.log('COM TRACKS: ', tracks);
      // console.log('COM CONTAINER: ', container)
      tracks.forEach(track => {
        // console.log('vai chamar track: ', track)
        this.addTrack(track, container);
        });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  removeParticipantMedia = (participant: Object) => {
    try {
      const tracks = [...participant.tracks.values()];
      tracks.forEach(this.removeTrack);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  setVideoCallEvents = (room: Object, container: Object) => {
    // console.log('VAI SETAR OS EVENTOS DA CHAMADA NA SALA: ', room);
    try {
      room.on('trackSubscribed', track => {
        // console.log('FOI ADD PELO ADDED', track);
        this.addTrack(track, container);
      });
      room.on('trackUnsubscribed', this.removeTrack);

      room.on('participantConnected', participant => this.addParticipantMedia(participant, container));
      room.on('participantDisconnected', this.removeParticipantMedia);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // const toogleTrack = (track: Object, container: Object, tag: string) => {
  //   if (!container.querySelector(tag)) {
  //     addTrack(track, container);
  //   } else {
  //     removeTrack(track);
  //   }
  // };

  // ==================== CONTROLLER API ====================

  // export const initiateVideoCall = async (token: string, options: Object, media: Object) => {
  //   // console.log('as optssss', options);
  //   if (media && options.accessToken) {
  //     // console.log('options connect: ', options);
  //     media.connect({ ...options, enableRemoteAudio: true, enableAudio: true, enableVideo: true });
  //     return false;
  //   } else {
  //     // console.log('vai chamar o disconnect');
  //     return true;
  //   }
  // };

  // Attach the Track to the DOM.
  attachTrack = (track, container) => {
    container.appendChild(track.attach());
    const video = container.querySelector('video');
    // console.log('no attachTrack: ', container, video);

    if (video) {
      // console.log('IDENTIFICOU VIDEO E VAI MUDAR STYLE');
      video.setAttribute('class', styles.video);
    }
  }

  // Attach array of Tracks to the DOM.
  attachTracks = (tracks, container) => {
    // console.log('attachTracks: ', tracks, container)
    tracks.forEach(function(track) {
      // console.log('attachTracks forEach: ', track)
      this.attachTrack(track, container);
    });
  }

  // Detach given track from the DOM.
  detachTrack = (track) => {
    // // console.log('desligando cada')
    // track.stop();
    track.detach().forEach(function(element) {
      // console.log('removeu cada')
      element.remove();
    });
  }

  // A new RemoteTrack was published to the Room.
  // trackPublished = (publication, container) => {
  //   if (publication.isSubscribed) {
  //     this.attachTrack(publication.track, container);
  //   }
  //   publication.on('subscribed', function(track) {
  //     // console.log('Subscribed to ' + publication.kind + ' track');
  //     // this.attachTrack(track, container);
  //     container.appendChild(track.attach());
  //   });
  //   publication.on('unsubscribed', this.detachTrack);
  // }


  // A RemoteTrack was unpublished from the Room.
  trackUnpublished = (publication) => {
    // console.log(publication.kind + ' track was unpublished.');
  }

  // A new RemoteParticipant joined the Room
  participantConnected = (participant, container) => {
    let selfContainer = document.createElement('div');
    selfContainer.id = `participantContainer-${participant.identity}`;

    const trackPublished = (publication, container) => {
      if (publication.isSubscribed) {
        this.attachTrack(publication.track, container);
      }
      publication.on('subscribed', function(track) {
        // console.log('Subscribed to ' + publication.kind + ' track');
        // this.attachTrack(track, container);
        container.appendChild(track.attach());

        const video = selfContainer.querySelector('video');
        // console.log('setando video height no trackPublished: ', video, selfContainer)
        if (video) {
          video.style.height = '100vh';
          video.style.width = '100vw';
        }
      });
      publication.on('unsubscribed', this.detachTrack);
    }

    // container.appendChild(selfContainer);
    // console.log('alguem conectou: ', participant, container)
    this.remoteMedia.appendChild(selfContainer);

    const video = selfContainer.querySelector('video');
    // console.log('setando video height no participantConnected: ', video, selfContainer)
    if (video) {
      video.style.height = '100%';
    }

    participant.tracks.forEach(function(publication) {
      trackPublished(publication, selfContainer);
    });
    participant.on('trackPublished', function(publication) {
      trackPublished(publication, selfContainer);
    });
    participant.on('trackUnpublished', this.trackUnpublished);
  }

  // Detach the Participant's Tracks from the DOM.
  detachParticipantTracks = (participant) => {
    var tracks = this.getTracks(participant);
    tracks.forEach(this.detachTrack);
  }

  // Get the Participant's Tracks.
  getTracks = (participant) => {
    // console.log('getTracks ', participant)
    return Array.from(participant.tracks.values()).filter(function(publication) {
        return publication.track;
      }).map(function(publication) {
        return publication.track;
      });
  }

  // Successfully connected!
  roomJoined = (room) => {

    // Attach LocalParticipant's Tracks, if not already attached.
    var previewContainer = this.localMedia;
    // console.log('no roomJoined: ', previewContainer)
    if (!previewContainer.querySelector('video')) {
      // console.log('vai botar os tracks pq nao tem video')
      this.attachTracks(this.getTracks(room.localParticipant), previewContainer);
    }

    // Attach the Tracks of the Room's Participants.
    var remoteMediaContainer = this.remoteMedia;
    room.participants.forEach(function(participant) {
      // console.log("Already in Room: '" + participant.identity + "'");
      this.participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant joins the Room, log the event.
    room.on('participantConnected', function(participant) {
      // console.log("Joining: '" + participant.identity + "'");
      this.participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on('participantDisconnected', function(participant) {
      // console.log("RemoteParticipant '" + participant.identity + "' left the room");
      this.detachParticipantTracks(participant);
    });



}












  /////////////////////////////////////////////////////////////////////////////


  appendTrackToDiv = (track: Object) => {
    const video = this.remoteMedia.querySelector('video');
    // console.log('No appendTrackToDiv', this.remoteMedia, video);

    if (video) {
      // console.log('IDENTIFICOU VIDEO E VAI MUDAR STYLE');
      video.setAttribute('class', styles.video);
    } else {
      // console.log('CONTAINER null: ', this.remoteMedia, video)
      this.remoteMedia.appendChild(track.attach());
      // Sentry.withScope(scope => {
      //   scope.setExtra("track", track)
      //   Sentry.captureException(new Error('Sem vídeo associado. Container null!'))
      // });
    }
  }

  // trackPublished = (publication, participant) => {
  //   // console.log(`RemoteParticipant ${participant.identity} published a RemoteTrack: ${publication}`);
  //   // qd perceber que adicionou audio e video eu attach no media
  //   participant.tracks.forEach(publication => {
  //     // console.log('tracks do participant: ', publication, publication.isSubscribed)
  //     if (publication.isSubscribed) {
  //       const track = publication.track;
  //       // console.log('track q vai adicionar: ', track)
  //       this.appendTrackToDiv(track);
  //     }
  //   });
  //
  //   participant.on('trackSubscribed', track => {
  //     // console.log(`trackSubscribed to a RemoteTrack: ${track}`);
  //     // this.remoteMedia.appendChild(track.attach());
  //     this.appendTrackToDiv(track);
  //   });
  //
  //
  //
  //   publication.on('subscribed', track => {
  //     // console.log(`LocalParticipant subscribed to a RemoteTrack: ${track}`);
  //   });
  //
  //   publication.on('unsubscribed', track => {
  //     // console.log(`LocalParticipant unsubscribed from a RemoteTrack: ${track}`);
  //     // aqui eu tiro os attach
  //   });
  // }


  connectToParticipantTracks = (participant: Object) => {
    participant.tracks.forEach(publication => {
      this.trackPublished(publication, participant);
    });

    participant.on('trackPublished', publication => {
      // console.log(`RemoteParticipant ${participant.identity} trackPublished: ${publication}`);
      this.trackPublished(publication, participant);
    });

    participant.on('trackUnpublished', publication => {
      // console.log(`RemoteParticipant ${participant.identity} unpublished a RemoteTrack: ${publication}`);
    });

  }

  initiateVideoCall = async (token: string, options: Object, media: Object,
    alertMessageCallback: Function, disconnectCallback: Function): Promise<Object> => {
    // SETANDO AS MEDIAS PARA PODERMOS ADICIONAR OS VIDEOS DPS
    this.setMedia(media.local, 'local');
    this.setMedia(media.remote, 'remote');

    const { roomName } = options;
    console.log('no initiateVideoCall: ', roomName)
    const connectionOptions = {
      audio: true,
      name: roomName,
      video: { width: 640 }
    }

    alertMessageCallback('Inicializando a câmera...');
    const localVideoTrack = await Video.createLocalVideoTrack();
    this.setLocalTrack(localVideoTrack, 'video');
    const localAudioTrack = await Video.createLocalAudioTrack();
    this.setLocalTrack(localAudioTrack, 'audio');
    const room = await Video.connect(token, {
      name: roomName,
      tracks: [localVideoTrack, localAudioTrack]
      });
    alertMessageCallback('Entrando na chamada...');
    const localParticipantTracks = room.localParticipant.tracks;
    localParticipantTracks.forEach(publication => {
      media.local.appendChild(publication.track.attach());
      });

    alertMessageCallback('Para reconectar-se à chamada, recarregue a página :)')
    room.participants.forEach(this.participantConnected);
    room.on('participantConnected', this.participantConnected);
    const video = media.local.querySelector('video');
    if (video) {
      video.style.height = '100%';
    }

    const detachParticipantTracks = (participant) => {
      // console.log('tirando os tracks do participant')
      var tracks = this.getTracks(participant);
      tracks.forEach(this.detachTrack);
    }

    room.on('participantDisconnected', function(participant) {
      // console.log("RemoteParticipant '" + participant.identity + "' left the room");
      detachParticipantTracks(participant);
      detachParticipantTracks(room.localParticipant);
      // console.log('chamando o disconnect')
      room.disconnect();
      alertMessageCallback('Seu terapeuta desconectou da chamada.');
    });
    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on('disconnected', function(room) {
      // if (previewTracks) {
      //   previewTracks.forEach(function(track) {
      //     track.stop();
      //   });
      //   previewTracks = null;
      // }
      // Detach the local media elements
      // console.log('tirando tudo')
      room.localParticipant.tracks.forEach(publication => {
        // console.log('desligando as coisas')
        publication.track.stop();
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
        // console.log('voltando')
        // redirect();
      });
      detachParticipantTracks(room.localParticipant);
      room.participants.forEach(detachParticipantTracks);
    });

    return room;

    // return Video.connect(token, connectionOptions)
    //   .then(room => {
    //     // console.log('room: ', room);
    //     setVideoCallEvents(room, media.remote);
    //     // // console.log('RECEBEU A ROOM DO TWILIO');
    //     // // console.log(room);
    //     // VideoServer.confirmVideocall(therapist, patient)
    //     //   .catch(error => {
    //     //     sendErrorToSentry(error, patient, therapist);
    //     //   });
    //     addParticipantMedia(room.localParticipant, media.local);
    //     return room;
    //   })
    //   .catch(error => {
    //     // console.log('DEU MERDA NO initiateVideoCall: ', error)
    //     // sendErrorToSentry(error, patient, therapist);
    //   });
  };
  
  setVideoAndReload = (dispatch, token) => {
    Cookies.set('video', JSON.stringify(token));

    dispatch(UserActions.saveVideoToken(token));
  }

  setVideoCallListener = (dispatch: Dispatch, chatId: string) => {
    FirebaseDatabase.turnOffListener(`chats/${chatId}/patientVideoToken`);
    console.log('setou turn off')
    FirebaseDatabase.listenOnValue(`chats/${chatId}/patientVideoToken`, token => this.setVideoAndReload(dispatch, token)
    );
    console.log('setou listen')
  };

  //
  disconnectVideoCall = (room: Object, options: Object) => {
    const { therapist, patient } = options;

    if (room) {
      try {
        // console.log('unpublishing tracks: ')
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          // console.log('detach cada track do local')
          attachedElements.forEach(element => element.remove());
        });

        room.localParticipant.unpublishTrack(this.localVideoTrack);
        // room.localParticipant.unpublishTrack(this.localAudioTrack);
        // console.log('track: ', this.localAudioTrack)
        console.log('chamando o disconnect')
        room.disconnect();
      } catch (error) {
        console.log('merda no disconnect: ', error)
        // this.sendErrorToSentry(error, patient, therapist);
      }
    }

    // VideoServer.deleteTokens(therapist, patient)
    //   .then(response => {})
    //   .catch(error => {
    //     sendErrorToSentry(error, patient, therapist);
    //   });
  };
}

export default new VideoController();
