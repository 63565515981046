import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Header.module.css';
import Cookies from 'js-cookie';
import { HeaderActions } from '../../../redux/actions';
const logo = require('../../../assets/images/logo.png')

const Header = (props) => {
  const dispatch = useDispatch();
  const [allow, setAllow] = useState(false);
  const headerButton = useSelector(state => state?.headerButton);
  const token = Cookies.get('token');

  useEffect(() => {
    setAllow(headerButton)
  }, [headerButton])
  const history = useHistory();

  const toHome = () => {
    token && history.push('/home');
  }

  const logout = () => {
    headerButton && setAllow(false)
    localStorage.clear();
    Cookies.remove('video');
    Cookies.remove('tokenVideo');
    Cookies.remove('videoToken');
    Cookies.remove('token');
    Cookies.remove('uid');
    Cookies.remove('cid');
    history.push('/')
    // dispatch(HeaderActions.addHeaderButton(false));
    window.location.reload();
  }

  return (
    <header className={styles.container}>
      <div className={styles.inline}>
        <img onClick={() => toHome()} alt='site under construction' className={styles.logo} src={logo} />
        {allow && <div className={styles.logout}>
          <button onClick={() => logout()}>Sair</button>
        </div>}
      </div>
    </header>
  )
}

export default Header;