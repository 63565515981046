// @flow
import * as firebase from 'firebase/app';
import 'firebase/auth';

export const logIn = (credentials: FirebaseCredentials): Promise<{}> => {
  const { email, password } = credentials;
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => firebase.auth().signInWithEmailAndPassword(email, password));
};

export const isAuthenticated = (): Promise<void> => {
  return new Promise((resolve, reject) =>
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        resolve(user.uid);
      } else {
        reject();
      }
    })
  );
};

export const logOut = () => firebase.auth().signOut();
