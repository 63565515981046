// @flow
import * as AuthTypes from '../../types/AuthTypes';

const initialState: ServerAuth = {
  isAuthenticated: false,
  signInError: false
};

export default (state: ServerAuth = initialState, action: Action): ServerAuth => {
  const { type } = action;
  switch (type) {
    case AuthTypes.LOGIN_SERVER_SUCCESS:
      return { ...state, isAuthenticated: true, signInError: false };
    case AuthTypes.LOGIN_SERVER_FAILED:
      return { ...state, isAuthenticated: false, signInError: true };
    case AuthTypes.LOGOUT_SERVER_SUCCESS:
      return { ...state, isAuthenticated: false, signInError: false };
    default:
      return state;
  }
};
