import * as HeaderTypes from '../types/HeaderTypes';

const initialState = false;

export default (state: boolean = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case HeaderTypes.ADD_HEADER_BUTTON:
      return payload;
    default:
      return state;
  }
};
