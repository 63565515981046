import React from 'react';
import styles from "./defaultCard.module.css";


const DefaultCard = (props) => {
  const { title, subtitle, icon, style, fullImage, onPress } = props;
  return (
    <div onClick={() => onPress && onPress()} className={style ? [styles.container, style].join(' ') : styles.container}>
      {icon && <div className={styles.iconContainer}><img className={styles.icon} src={icon} /></div>}
      {fullImage 
        ? (<img className={styles.full} src={fullImage} />) 
        : (
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        )}
    </div>
  )
}

export default DefaultCard;