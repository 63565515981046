// @flow
import * as UserTypes from '../types/UserTypes';

const initialState: UserProfile = {
  thumbnail: undefined,
  name: undefined,
  email: undefined,
  id: undefined,
  videoAccessToken: undefined
};

export default (state: UserProfile = initialState, action: Action): UserProfile => {
  const { type, payload } = action;
  switch (type) {
    case UserTypes.SAVE_USER_PROFILE:
      return { ...state, ...payload };
    case UserTypes.CLEAR_USER_PROFILE:
      return { ...payload };
    case UserTypes.SAVE_VIDEO_ACCESS_TOKEN:
      return { ...state, ...payload };
    case UserTypes.DELETE_VIDEO_ACCESS_TOKEN:
      return { ...state, videoAccessToken: null };
    default:
      return state;
  }
};
