// @flow
import React from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { type RouterHistory } from "react-router-dom";
import Cookies from 'js-cookie';


// import { ErrorBoundary } from '../reusable';
import VideoController from "./controller";
import styles from "./Video.module.css";

type Props = {
  history: RouterHistory,
  therapist: number,
  patient: number,
  roomName: string,
};

type State = {
  room: Object,
  alertMessage: string,
};

class VideoScreen extends React.Component<Props, State> {
  media: Object = {};

  state = {
    room: null,
    accessToken: null,
    alertMessage: "Entrando na consulta...",
    errorMessage:
      "Por favor cheque se sua webcam e microfone estão permitidos para serem utilizados no navegador e reinicie a chamada",
    errorToast: false,
  };

  errorBoundary: { current: ?React$ElementRef<*> } = React.createRef();
  // errorBoundary: { current: ?React$ElementRef<*> } = React.createRef();
  // errorBoundary: { current: ?React$ElementRef<*> } = React.createRef();

  componentDidMount = async () => {
    // console.log('vai pegar o token');
    const { chatId, getAccessToken } = this.props;
    console.log('chatId: ', chatId)

    const localUser = localStorage.getItem("user");
    // console.log('vi pegar no componentDidMount do Video o localStorage: ', localUser, JSON.parse(localUser))

    // se nao tiver chatId do redux pq ele tinha logado ants tenta pegar do localStorage
    const currentChatId = localUser ? JSON.parse(localUser).uid : chatId;
    // console.log('currentChatId: ', currentChatId)
    // if (localUser) {
    //   // console.log('tem localStorage: ', localUser)
    //   const user = JSON.parse(localUser)
    // }
    
    const chat_id = Cookies.get('cid');
    const token = await getAccessToken(chat_id.slice(1, chat_id.length - 1));


    this.setState({ accessToken: token?.substring(1) });

    this.connect();
    VideoController.listenToVideoTokenActivation(
      chat_id.split('-')[0],
      (accessToken: string) => {
        this.setState({ accessToken: token });
        this.connect();
      }
    );
    window.onbeforeunload = () => "";
  };

  componentWillUnmount = () => {
    this.disconnect();
  };

  getLocalMediaRef = (ref: Object) => {
    this.media.local = ref;
  };

  getRemoteMediaRef = (ref: Object) => {
    this.media.remote = ref;
  };

  requestDisconnect = () => {
    const { history } = this.props;
    if (window.confirm("Você deseja realmente encerrar a consulta?")) {
      this.disconnect();
      history.replace('/home');
    }
  };

  disconnectCallback = () => {
    this.setState({ alertMessage: "Seu terapeuta desconectou da chamada." });
  };

  alertMessageCallback = (alertMessage: string) => {
    this.setState({ alertMessage });
  };

  connect = () => {
    const { therapist, patient, roomName, history } = this.props;
    const { accessToken } = this.state;
    console.log('o token', accessToken, roomName);
    const cookieVideo = Cookies.get('video');
    const videoRoom = cookieVideo && JSON.parse(Cookies.get('video'));
    // const options = { roomName };
    console.log('roomName', roomName, accessToken)
    // console.log('this.media: ', this.media)
    const redirect = () => history.goBack();
    // console.log('redirect: ', redirect)
    if (videoRoom) {
      VideoController.initiateVideoCall(
        accessToken,
        { roomName: videoRoom },
        this.media,
        this.alertMessageCallback
      )
        .then((room) => {
          // console.log('voltou a room:', room)
          this.setState({ room });
        })
        .catch((error) => {
          this.setState({
            errorToast: true,
            errorMessage: this.handleToast(error.message),
          });
          setTimeout(() => {
            this.setState({
              errorToast: false,
              errorMessage: "",
            });
          }, 8000);
          Sentry.withScope((scope) => {
            scope.setExtra("patient", patient);
            scope.setExtra("therapist", therapist);
            Sentry.captureException(error);
          });
          if (this.errorBoundary.current) {
            this.errorBoundary.current.forceError();
          }
        });
    } else {
      this.setState({
        alertMessage: "Seu terapeuta ainda não está na chamada.",
      });
      history.push('/home')
    }
  };

  handleToast = (error) =>
    ({
      "Permission denied":
        "Por favor cheque se sua webcam e microfone estão permitidos para serem utilizados no navegador e reinicie a chamada",
      "Requested device not found":
        "Sua webcam ou microfone não foram encontrados, por favor habilite no seu sistema e reinicie a chamada",
    }[error]);

  disconnect = () => {
    const { therapist, patient } = this.props;
    const { room } = this.state;
    VideoController.disconnectVideoCall(room, { therapist, patient });
  };

  // toogleAudio = () => {
  //   const { room } = this.state;
  //   VideoController.toogleAudio(room, this.localMedia);
  // };

  // toogleVideo = () => {
  //   const { room } = this.state;
  //   VideoController.toogleVideo(room, this.localMedia);
  // };

  render = () => {
    const { alertMessage, errorMessage, errorToast } = this.state;
    // const errorMessage = {'Entre em contato com o suporte técnico da Moodar ' + 'e saiba como alcançar mais usuários.'}
    return (
      <div className={styles.container}>
        {errorToast ? (
          <div className={styles.errorDiv}>{errorMessage}</div>
        ) : null}

        <div className={styles.remoteMedia} ref={this.getRemoteMediaRef} />
        <div className={styles.control}>
          <div className={styles.alert}>
            <div className={styles.alertText}>{alertMessage}</div>
          </div>
          <div
            className={styles.iconContainer}
            onClick={this.requestDisconnect}
            role="button"
            tabIndex={0}
            onKeyUp={() => { }}
          >
            <div className={`fas fa-phone fa-2x ${styles.icon}`} />
          </div>
          <div className={styles.localMedia} ref={this.getLocalMediaRef} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: Store) => {
  const {
    user: { user, therapist },
  } = state;

  // console.log('mapStateToProps: ', state)

  return {
    therapist: therapist && therapist.id, // Ja que no local ele manda id e stage manda coach_id
    // Quando atualizar o webapp com a requisicao de therapist_profile do server vai mandar o id
    // therapist: profile && profile.coach_id,
    patient: user && user.id,
    roomName: user && user.uid,
    chatId: user && user.uid,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAccessToken: (chatId: string) =>
    VideoController.getAccessToken(chatId)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoScreen);
