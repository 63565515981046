import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FirebaseDatabase } from '../../firebase';
import VideoController from '../video/controller';
import { HeaderActions } from '../../redux/actions';
import { Spinner } from "../reusable";
import {
  getAllAppointments,
  separateAppointments,
  formatNextApposTitle,
  formatNextApposIcon,
  formatNextApposSubtitle
} from './controller';
import { DefaultCard } from '../reusable';
import styles from './home.module.css';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const videoTokenRedux = useSelector(state => state?.user?.videoAccessToken);
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState(null);
  const [nextAppointments, setNextAppointments] = useState([]);
  const [prevAppointments, setPrevAppointments] = useState([]);
  const [video, setVideo] = useState(null);
  const chat_id = Cookies.get('cid');
  const cookieUid = Cookies.get('uid');
  const cookieToken = Cookies.get('token');
  const cookieVideo = Cookies.get('video');
  const userId = cookieUid && JSON.parse(Cookies.get('uid'));
  const chatId = chat_id && JSON.parse(Cookies.get('cid'));
  const name = JSON.parse(localStorage.getItem('name'));
  const token = cookieToken &&  JSON.parse(Cookies.get('token'));
  const userName = name?.split(' ')[0];

  const videoRoom = cookieVideo && JSON.parse(Cookies.get('video'));
  const building = require('../../assets/images/building.png')
  const calling = require('../../assets/images/calling.png')
  const attention = require('../../assets/images/attention.png')

  const sendToWhatsapp = () => {
    window.location.href = 'https://web.whatsapp.com/send?phone=5581991067299';
  }

  useEffect(() => {
    setVideo(videoTokenRedux);
  }, [videoTokenRedux]);

  useEffect(() => {
    !token && history.push('/login');
    getAllAppointments(userId, token).then(res => {
      setAppointments(res?.appointments?.reverse());
      separateAppointments(res?.appointments, setNextAppointments, setPrevAppointments);
      dispatch(HeaderActions.addHeaderButton(true));
      setLoading(false);
    }
    ).catch(err => console.log('error getting appointments', err))
    FirebaseDatabase.listenOnValue(`chats/${chatId}/patientVideoToken`, token =>
      VideoController.setVideoAndReload(dispatch, token)
    );
  }, [])

  return loading ? <Spinner /> :
    <div className={styles.container}>
      <div className={styles.name}>
        <h4 className={styles.bold}>Oi, </h4>
        <h4 className={styles.light}>{userName}</h4>
      </div>
      <div className={styles.flexing}>
        <div className={styles.appointments}>
            <h4>Próximas Consultas</h4>
          <div className={styles.scroll}>
            {videoRoom && (
              <div className={styles.video}>
                <DefaultCard
                  title={'Sua terapeuta está na chamada.'}
                  subtitle={'Clique para entrar'}
                  style={styles.calling}
                  icon={calling}
                  onPress={() => history.push('/video')}
                />
              </div>
            )}
            {nextAppointments?.length > 0 ? nextAppointments?.reverse()?.map((appointment, idx) => {
              return (
                <div key={idx} className={styles.appointment}>
                  <DefaultCard
                    title={formatNextApposTitle(appointment.schedule, idx)}
                    subtitle={formatNextApposSubtitle(appointment.status)}
                    icon={formatNextApposIcon(appointment.status)}
                  />
                </div>
              )
            }) : (
              <div className={styles.appointment}>
                <DefaultCard
                  title={'Você não possui próximas consultas'}
                  subtitle={'Consulte o App para mais informações'}
                  icon={attention}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.construction}>
          <DefaultCard
            // title={'Default Card'} 
            // subtitle={'Subtitle'} 
            style={styles.high}
            fullImage={building}
            onPress={sendToWhatsapp}
          />
        </div>
      </div>
    </div>
}

export default React.memo(Home)