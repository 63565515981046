import React from 'react';
import { HashRouter, Switch, Route, Redirect, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Video from './views/video';
import Login from './views/login';
import Home from './views/home/Home';
import { Header } from './views/reusable';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

// import 'moment/locale/pt-br';

const App = () => {
  // Checando se tem user logado para ir para o Video ou nao
  const localUser = Cookies.get('token');
  const headerButton = useSelector(state => state?.headerButton);

  // Private route to check if user is logged in or not
  const PrivateRoute = (props) => {
    const {path, component} = props;
    return headerButton || localUser ?
    (<Route path={path} component={component} />) :
    (<Route path="/" component={Login} />)
  }

  // se nao tiver chatId do redux pq ele tinha logado ants tenta pegar do localStorage
  // console.log('local user', localUser)
  const currentView = localUser ? Home : Login;
  return (
    <HashRouter>
      <Header />
      <Switch>
        <PrivateRoute exact path="/" component={currentView} />
        <PrivateRoute path="/video" component={Video} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/login" component={Login} />
      </Switch>
    </HashRouter>
  );
};

export default App;
