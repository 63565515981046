import moment from 'moment';
import { AppointmentsAPI } from '../../server';
const Confirmed = require('../../assets/images/calendar-confirm.png')
const Canceled = require('../../assets/images/attention.png')
const Realized = require('../../assets/images/confirm.png')


export const getAllAppointments = (id, token) => {
  return AppointmentsAPI.retrieveAppointments({ id, token });
};

export const getAppointmentDuration = (planName: string) => {
  if (planName.includes('basica') || planName.includes('basico')) {
    return 20;
  } else if (planName.includes('johnson')) {
    return 40;
  } else {
    return 40;
  }
};

// export const getNextAppointments = () => {
//   const nextAppointments = AppointmentsDatabase.getNextAppointments(new Date());
//   return nextAppointments;
// };

export const getCurrentLocalDate = () => {
  const today = new Date();
  const localTime = today.getTime();
  const localOffset = today.getTimezoneOffset() * 60000;
  const utc = localTime + localOffset;
  const offset = -3.0;
  const localUTC = utc + 3600000 * offset;
  const currentDate = moment(localUTC);
  return currentDate;
};

/*
GAMBIARRA PRA HORÁRIO LOCAL DO PACIENTE
*/

// export const getPreviousAppointments = planName => {
//   const prevAppointments = getPrevAppointments();
//   const appointmentDuration = getAppointmentDuration(planName);
//   const currentDate = getCurrentLocalDate();
//   return prevAppointments.filter(filtered => {
//     let diff = currentDate.diff(moment(filtered.schedule), 'minutes');
//     return diff > appointmentDuration;
//   });
// };

export const getFutureAppointments = planName => {
  const nextAppointments = getAllAppointments().filter(
    appointment => appointment.status !== 'reserved'
  );
  const appointmentDuration = getAppointmentDuration(planName);
  const currentDate = getCurrentLocalDate();
  return nextAppointments
    .filter(filtered => {
      let diff = currentDate.diff(moment(filtered.schedule), 'minutes');
      return diff <= appointmentDuration;
    })
    .sort((x, y) => x.schedule - y.schedule);
};

export const formatNextApposTitle = (titleToFormat, idx) => {
  const formated = `${moment(titleToFormat).format('dddd')}, `
    + 'dia '
    + `${moment(titleToFormat).format('LL')}, `
    + `às ${moment(titleToFormat).format('HH')}`
    + 'h'
  return formated;
}

export const formatNextApposSubtitle = (status) => {
  switch (status) {
    case 'confirmed':
      return 'Confirmada';
    case 'successful':
      return 'Realizada';
    case 'canceled':
      return 'Cancelada';
    default:
      return '';
  }
}

export const formatNextApposIcon = (status) => {
  switch (status) {
    case 'confirmed':
      return Confirmed;
    case 'successful':
      return Realized;
    case 'canceled':
      return Canceled;
    default:
      return '';
  }
}

export const separateAppointments = (appointments, setNextAppointments, setPrevAppointments) => {
  const next = [];
  const prev = [];
  appointments.forEach(appointment => {
    appointment.schedule > moment().format('YYYY-MM-DD HH:mm')
      ? next.push({ schedule: appointment.schedule, status: appointment.status })
      : prev.push({ schedule: appointment.schedule, status: appointment.status })
  });
  setNextAppointments(next);
  setPrevAppointments(prev);
}
