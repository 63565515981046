// @flow
import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Spinner } from "../reusable";
import Logo from "../../assets/images/icon.png";
import styles from "./Login.module.css";

type Props = {
  logInUser: (
    credentials: Object,
    callback: Function
  ) => void,
  errorMessage: string
};

export default class LoginStateless extends React.Component<Props> {
  getValidatorSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Email inválido")
        .required("Campo obrigatório"),
      password: Yup.string().required("Campo obrigatório")
    });

  onSubmitCredentials = (
    credentials,
    { setSubmitting }: { setSubmitting: boolean => void }
  ) => {
    const { logInUser } = this.props;
    // console.log('onSubmitCredentials: ', credentials)
    logInUser(credentials, () => setSubmitting(false));
  };

  renderFeedback = (isSubmitting: boolean) => {
    const { errorMessage } = this.props;

    if (isSubmitting) {
      return <Spinner size="2em" />;
    }
    if (errorMessage) {
      return <small className={styles.error}>{errorMessage}</small>;
    }
    return null;
  };

  render() {
    return (
      <div className={styles.container}>
        <img className={styles.logo} src={Logo} alt="Moodar Logo" />
        <div className={styles.card}>
          <h3>Entrar</h3>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={this.getValidatorSchema()}
            onSubmit={this.onSubmitCredentials}
          >
            {({ isSubmitting }) => (
              <React.Fragment>
                {this.renderFeedback(isSubmitting)}
                <Form className={styles.form}>
                  <Field
                    className={styles.field}
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    className={styles.error}
                    name="email"
                    component="small"
                  />
                  <Field
                    className={styles.field}
                    type="password"
                    name="password"
                    placeholder="Senha"
                  />
                  <ErrorMessage
                    className={styles.error}
                    name="password"
                    component="small"
                  />
                  <button
                    className={styles.button}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    ENTRAR
                  </button>
                </Form>
              </React.Fragment>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
