// @flow
import React from "react";
import type { RouterHistory, Location } from "react-router-dom";
import { connect } from "react-redux";
import * as AuthController from "./controller";
import LoginStateless from "./Login.stateless";
import Cookie from 'js-cookie';

type Props = {
  logIn: (
    credentials,
    callback: Function
  ) => PromiseAction,
  history: RouterHistory,
  location: Location
};

type State = {
  errorMessage: string
};

class Login extends React.Component<Props, State> {
  state = { errorMessage: "" };

  componentDidMount() {
    const { history } = this.props;
    const token = Cookie.get('token');
    token && history.push('/home');
  }

  logInUser = (
    credentials,
    callback: Function
  ) => {
    const { logIn, history, location } = this.props;
    // console.log('props no Login: ', history, location)

    this.setState({ errorMessage: "" });
    logIn(credentials)
      .then(userProfile => {
        history.replace("/home");
        // window.location.reload();
      })
      .catch(error => {
        console.log('error', error?.message)
        this.setState({ errorMessage: "Ocorreu um erro. Tente novamente." });
      }
      )
      .finally(callback);
  };

  render() {
    const { errorMessage } = this.state;
    return (
      <LoginStateless errorMessage={errorMessage} logInUser={this.logInUser} />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logIn: credentials => AuthController.logIn(credentials)(dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(Login);
