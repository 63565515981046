// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
//
// ReactDOM.render(<App />, document.getElementById('root'));
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { FirebaseCore } from './firebase';

// import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './redux/store';
import './index.css';

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

Sentry.init({ dsn: 'https://7c05f469e4e64f0b9f3410e8141bb855@sentry.io/1867527' });

FirebaseCore.initApp();
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
