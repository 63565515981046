// @flow

// export const BASE_SERVER_URL: string = 'http://dev.moodar.com.br/api/'; // stage
// export const BASE_SERVER_URL: string = 'http://localhost:8000/api/'; // stage
export const BASE_SERVER_URL: string = 'https://webapp.moodar.com.br/api/'; // production

const serverErr = (res: Object) => {
  const errMessage = `Request to ${res.url} returned status ${res.status}`;
  // console.error(errMessage);
  return Promise.reject(Error(errMessage));
};

export const fetchServerData = (
  path: Array<string>,
  body: Object = {},
  method: string = 'GET'
): Promise<Object> => {
  const url: string = `${BASE_SERVER_URL}${path.join('/')}`;
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', body?.token && `Token ${body?.token}`)

  const options: Object = {
    method,
    headers,
    credentials: 'include',
    body: method !== 'GET' ? JSON.stringify(body) : undefined
  };

  return fetch(url, options).then((res: Object) => (res.ok ? res.json() : serverErr(res)));
};
